import { Route } from '@angular/router';
import { LoginComponent } from './auth/login.component';
import { FrameComponent } from './frame/frame.component';
import { AuthGuard } from './auth.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'credentials',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FrameComponent,
    children: [
      {
        path: 'credentials',
        loadChildren: () =>
          import('./templates/templates.module').then((m) => m.TemplatesModule),
      },
      {
        path: 'presentations',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./presentations/presentations.module').then(
            (m) => m.PresentationsModule
          ),
      },
      {
        path: 'dids',
        loadChildren: () =>
          import('./dids/dids.module').then((m) => m.DidsModule),
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];
