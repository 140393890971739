<div *ngIf="!loaded && !error" fxLayout="column" fxLayoutAlign="center center">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <p>Connecting to server...</p>
</div>
<div class="error" fxLayout="row" fxLayoutAlign="center center" *ngIf="error">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Error</mat-card-title>
    </mat-card-header>
    <mat-card-content>{{ error }}</mat-card-content>
    <mat-card-actions align="end">
      <button mat-button (click)="logout()">Logout</button>
    </mat-card-actions>
  </mat-card>
</div>
<mat-drawer-container class="container" *ngIf="loaded">
  <mat-drawer mode="side" opened class="sidenav">
    <mat-toolbar color="primary">Agent</mat-toolbar>
    <div class="namespaces">
      <mat-form-field>
        <mat-label>Namespace</mat-label>
        <mat-select
          (selectionChange)="nameSpaceChanged($event)"
          [value]="namespace"
        >
          <mat-option *ngFor="let ns of namespaces" [value]="ns">
            {{ ns }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-nav-list>
      <!-- TODO: links should be shown based on functions of the agent -->
      <a
        mat-list-item
        routerLink="/credentials"
        [routerLinkActive]="['link-active']"
      >
        Credentials
      </a>
      <a
        mat-list-item
        routerLink="/presentations"
        [routerLinkActive]="['link-active']"
      >
        Presentations
      </a>
      <a mat-list-item routerLink="/dids" [routerLinkActive]="['link-active']">
        DIDs
      </a>
      <a
        mat-list-item
        routerLink="/clients"
        [routerLinkActive]="['link-active']"
      >
        Clients</a
      >
      <div *ngIf="admin">
        <mat-divider></mat-divider>
        <a
          mat-list-item
          routerLink="/admin/namespaces"
          [routerLinkActive]="['link-active']"
        >
          Namespaces
        </a>
        <a
          mat-list-item
          routerLink="/admin/users"
          [routerLinkActive]="['link-active']"
          >Users</a
        >
      </div>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content
    ><mat-toolbar
      color="primary"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <ssi-breadcrumb></ssi-breadcrumb>
      <button class="icon-button" [matMenuTriggerFor]="menu" *ngIf="user">
        <img class="icon" [src]="userImage" title="user image" />
      </button>
      <mat-menu #menu="matMenu">
        <div class="user-info">
          Logged in as <br />
          {{ user?.email }}
        </div>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </mat-toolbar>
    <div id="content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
