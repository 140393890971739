import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { AgentService } from './agent.service';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { BreadcrumpModule } from './breadcrumb/breadcrumb.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthModule } from './auth/auth.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FrameComponent } from './frame/frame.component';
import { MatCardModule } from '@angular/material/card';
import { environment } from '../environments/environment';
import { MatSelectModule } from '@angular/material/select';
import {
  KeycloakAngularModule,
  KeycloakEventType,
  KeycloakService,
} from 'keycloak-angular';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { QRCodeModule } from 'angularx-qrcode';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

function init(keycloak: KeycloakService) {
  return async () => {
    const config = await fetch('/assets/config.json').then((res) => res.json());
    //TODO: we can validate the config and alert the user that some values are missing.
    window.env = config;
    return keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId,
      },
      initOptions: {
        checkLoginIframe: true,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
    });
  };
}

@NgModule({
  declarations: [AppComponent, FrameComponent, FrameComponent],
  imports: [
    BrowserModule,
    KeycloakAngularModule,
    RouterModule.forRoot(appRoutes),
    FlexLayoutModule,
    AuthModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    BreadcrumpModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressBarModule,
    MatSidenavModule,
    QRCodeModule,
  ],
  providers: [
    AgentService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-DE',
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(keycloakService: KeycloakService) {
    keycloakService.keycloakEvents$.subscribe({
      next(event) {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          keycloakService.updateToken(20);
        }
      },
    });
  }
}
