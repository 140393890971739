import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'ssi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(keycloakService: KeycloakService) {
    keycloakService.login({
      redirectUri: window.location.origin,
    });
  }
}
