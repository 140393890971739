import { Component } from '@angular/core';
import { AgentService } from '../agent.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'ssi-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
})
export class FrameComponent {
  user?: KeycloakProfile;
  loaded = false;
  error?: string;
  admin = false;
  //TODO: set something nice
  userImage = '';
  namespaces: string[] = [];
  namespace?: string;

  constructor(
    private keycloakService: KeycloakService,
    private agentService: AgentService
  ) {
    //TODO: we have no admin board to add a new agent to the system.
    /**
     * Steps to do:
     * A new namespace has to be created. This can be done via the angular application with a specific route
     * With a new namespace we should add a new role in auth0
     * We can add a new user to the namespace and add the role at auth0
     * This will not allow the user to register himself, but on the other hand we control the flow.
     *
     * Open question: where to handle the user to namespace connection? Auth0 could be one approach. Another would be to manage it via the backend.
     */
    this.init();
  }

  private async init() {
    const isAuthenticated = this.keycloakService.isLoggedIn();
    if (!isAuthenticated) {
      await this.keycloakService.login({
        redirectUri: window.location.origin,
      });
      return;
    }
    //maybe we only need to username
    this.user = await this.keycloakService.loadUserProfile();

    //create the hash from the username
    crypto.subtle
      .digest('SHA-256', new TextEncoder().encode(this.user.username))
      .then((hashBuffer) => {
        // Convert the ArrayBuffer to a hexadecimal string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, '0'))
          .join('');
        this.userImage = `https://www.gravatar.com/avatar/${hashHex}?d=identicon`;
      });
    this.connectAgent();
    this.admin = this.keycloakService.isUserInRole('admin');
  }

  //TODO: in case of an admin it's okay to not have a namespace sine he only need to administrate them.
  private async connectAgent() {
    this.namespace = localStorage.getItem('namespace') as string;
    this.namespaces = this.keycloakService
      .getUserRoles()
      .filter((role) => role.startsWith('namespace:'))
      .map((role) => role.split(':')[1]);

    const namespace = localStorage.getItem('namespace') || this.namespaces[0];
    await this.agentService.init(namespace).then(
      //TODO: implement a better loading option.
      () => (this.loaded = true),
      (error: Error) => {
        alert(`Error connecting to agent: ${error.message}`);
        //TODO: redirect to a page so the user can use a namespace
        // //maybe the used namespace is not valid anymore, try another one
        // this.namespaces = this.namespaces.filter((n) => n !== namespace);
        // if (this.namespaces.length > 0) {
        //   localStorage.setItem('namespace', this.namespaces[0]);
        //   this.namespace = this.namespaces[0];
        //   console.log('try another namespace:', this.namespaces[0]);
        //   this.connectAgent();
        // } else {
        //   this.error = error.message;
        // }
        if (this.admin) {
          this.loaded = true;
          this.error = undefined;
        }
      }
    );
  }

  async nameSpaceChanged($event: MatSelectChange) {
    if (!confirm('Are you sure you want to change the namespace?')) return;
    localStorage.setItem('namespace', $event.value);
    location.reload();
  }

  logout() {
    this.keycloakService.logout();
  }
}
